<template>
  <div class="memberSMS">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>会员短信</span>
          <p>
            会员短信：企业=短信主要作用为挖掘会员的消费能力，将促销变为优惠和关怀，提升会员消费体验和用户粘性。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/memberSMS/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">会员短信特殊优势</span>
        <div class="p2m-major">
          <div class="p2m-major-left">
            <div
              class="p2m-major-item reveal-bottom active"
              @mousemove="p2Move($event)"
            >
              <img
                src="../../assets/img/product/memberSMS/part2-icon1.png"
                alt=""
              />
              <div>
                <span>个性发送</span>
                <p>
                  操作便捷、模板自制、内容预审、根据需求提供自定义模板和自定义签名，成功计费、高效送达。
                </p>
              </div>
            </div>
            <div
              class="p2m-major-item reveal-bottom"
              @mousemove="p2Move($event)"
            >
              <img
                src="../../assets/img/product/memberSMS/part2-icon2.png"
                alt=""
              />
              <div>
                <span>一主多备</span>
                <p>
                  丰富的资源，灵活的调度和运营能力、三网配置一主多备，故障秒级自动切换。
                </p>
              </div>
            </div>
            <div
              class="p2m-major-item reveal-bottom"
              @mousemove="p2Move($event)"
            >
              <img
                src="../../assets/img/product/memberSMS/part2-icon3.png"
                alt=""
              />
              <div>
                <span>高覆盖</span>
                <p>
                  行业覆盖率高，适合各类会员行业，实时互动，统计报表快速反馈，增加用户转化率。
                </p>
              </div>
            </div>
          </div>
          <img
            class="reveal-bottom p2m-majorImg"
            src="../../assets/img/product/memberSMS/part2-right-bg.png"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="part3">
      <div class="part3_main">
        <span class="part-title">会员短信应用场景</span>
        <div class="p3m-major">
          <div class="p3m-major-Top reveal-bottom">
            <ul class="topUl">
              <li
                class="ul-item"
                v-for="(item, i) in p3Uldata"
                :key="i"
                @mouseover="topUlliMove(i)"
              >
                <div>{{ item }}</div>
                <span></span>
              </li>
            </ul>
          </div>
          <div class="p3m-major-Buttom reveal-bottom2">
            <div v-for="(item, i) in p3img" :key="i">
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MemberSMS',
  data() {
    return {
      p3Uldata: ['会员营销', '电商大促', '新品推荐', '品牌宣传'],
      p3img: [
        require('../../assets/img/product/memberSMS/part3-img1.png'),
        require('../../assets/img/product/memberSMS/part3-img2.png'),
        require('../../assets/img/product/memberSMS/part3-img3.png'),
        require('../../assets/img/product/memberSMS/part3-img4.png'),
      ],
    };
  },
  mounted() {
    // 初始化
    this.start();
    const that = this;
    $(window).resize(function () {
      that.start();
    });
  },
  methods: {
    topUlliMove(i) {
      if ($(window).width() > 1200) {
        // 初始化
        $('.ul-item').stop().animate({ paddingTop: '0px' }, 200);
        $('.ul-item').find('span').stop().animate({ top: '-15px' }, 200);
        $('.p3m-major-Buttom img')
          .stop()
          .animate(
            { left: '11.5px', width: '254px', height: '494px', opacity: '0.5' },
            200
          );
        // 执行单一条件
        $('.ul-item').eq(i).stop().animate({ paddingTop: '6px' }, 200);
        $('.ul-item').eq(i).find('span').stop().animate({ top: '0px' }, 200);
        $('.p3m-major-Buttom img')
          .eq(i)
          .stop()
          .animate(
            { left: '0px', width: '281px', height: '547px', opacity: '1' },
            200
          );
      } else {
        // 初始化
        $('.ul-item').stop().animate({ paddingTop: '0rem' }, 200);
        $('.ul-item').find('span').stop().animate({ top: '-.08rem' }, 200);
        $('.p3m-major-Buttom img').stop().animate(
          {
            left: '.115rem',
            width: '1.51rem',
            height: '2.94rem',
            opacity: '0.27',
          },
          200
        );
        // 执行单一条件
        $('.ul-item').eq(i).stop().animate({ paddingTop: '.08rem' }, 200);
        $('.ul-item').eq(i).find('span').stop().animate({ top: '0rem' }, 200);
        $('.p3m-major-Buttom img')
          .eq(i)
          .stop()
          .animate(
            { left: '0px', width: '1.66rem', height: '3.23rem', opacity: '1' },
            200
          );
      }
    },
    start() {
      if ($(window).width() > 1200) {
        // 初始化
        $('.ul-item').stop().animate({ paddingTop: '0px' }, 200);
        $('.ul-item').find('span').stop().animate({ top: '-15px' }, 200);
        $('.p3m-major-Buttom img')
          .stop()
          .animate(
            { left: '11.5px', width: '254px', height: '494px', opacity: '0.5' },
            200
          );
        // 执行单一条件
        $('.ul-item').eq(0).stop().animate({ paddingTop: '6px' }, 200);
        $('.ul-item').eq(0).find('span').stop().animate({ top: '0px' }, 200);
        $('.p3m-major-Buttom img')
          .eq(0)
          .stop()
          .animate(
            { left: '0px', width: '281px', height: '547px', opacity: '1' },
            200
          );
      } else {
        // 初始化
        $('.ul-item').stop().animate({ paddingTop: '0rem' }, 200);
        $('.ul-item').find('span').stop().animate({ top: '-.08rem' }, 200);
        $('.p3m-major-Buttom img').stop().animate(
          {
            left: '.115rem',
            width: '1.51rem',
            height: '2.94rem',
            opacity: '0.27',
          },
          200
        );
        // 执行单一条件
        $('.ul-item').eq(0).stop().animate({ paddingTop: '.08rem' }, 200);
        $('.ul-item').eq(0).find('span').stop().animate({ top: '0rem' }, 200);
        $('.p3m-major-Buttom img')
          .eq(0)
          .stop()
          .animate(
            { left: '0px', width: '1.66rem', height: '3.23rem', opacity: '1' },
            200
          );
      }
    },
    p2Move(e) {
      $('.p2m-major-item').removeClass('active');
      $(e.currentTarget).addClass('active');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一个部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .part1-title {
      width: 485px;
      height: 280px;
      span {
        font-family: FontExcellent;
        font-size: 40px;
        color: #333333;
        display: block;
        margin-bottom: 22px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        color: #666666;
        text-align: justify;
        margin-bottom: 78px;
        line-height: 28px;
      }
      a {
        display: block;
        width: 184px;
        height: 53px;
        background-color: rgb(36, 110, 187);
        border-radius: 7px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        color: #f9fbfd;
        letter-spacing: 0.5px;
      }
    }
    > img {
      width: 661px;
      height: 640px;
      margin-right: -138px;
      margin-top: 48px;
    }
  }
}
/* 第二个部分 */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 825px;
  .part-title {
    margin-bottom: 60px;
  }
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    span {
      margin-bottom: 94px;
    }
    .p2m-major {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p2m-major > img {
        width: 446px;
        height: 580px;
        margin-top: 35px;
        margin-right: -21px;
      }
      .p2m-major-left {
        width: 570px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p2m-major-item {
          box-sizing: border-box;
          width: 100%;
          height: 134px;
          display: flex;
          flex-direction: row;
          padding: 26px 0px;
          span {
            display: block;
            font-family: FontExcellent;
            font-size: 20px;
            color: #333333;
            margin-bottom: 7px;
          }
          p {
            display: block;
            width: 413px;
            font-size: 16px;
            color: #666666;
            line-height: 27px;
            text-align: justify;
          }
        }
        .p2m-major-item:nth-of-type(1) {
          padding-left: 25px;
          img {
            width: 58px;
            height: 73px;
            margin-right: 29px;
            margin-top: 5px;
          }
        }
        .p2m-major-item:nth-of-type(2) {
          padding-left: 26px;
          img {
            width: 68px;
            height: 58px;
            margin-right: 22.5px;
          }
        }
        .p2m-major-item:nth-of-type(3) {
          padding-left: 32px;
          img {
            width: 53px;
            height: 76px;
            margin-right: 24px;
            margin-top: 5px;
          }
        }
        .p2m-major-item.active {
          background: url('../../assets/img/product/number95/part2-item-bg.png')
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
/* 第三个部分 */
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 1067px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  .part-title {
    margin-bottom: 60px;
  }
  .part3_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 119px;
    span {
      margin-bottom: 94px;
    }
    .p3m-major {
      width: 1110px;
      margin: 0 auto;
      .p3m-major-Top {
        margin-bottom: 50px;
        .topUl {
          list-style: none;
          box-sizing: border-box;
          overflow: hidden;
          width: 100%;
          height: 64px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-top: 1px solid rgb(225, 228, 230);
          border-bottom: 1px solid rgb(225, 228, 230);
          .ul-item {
            box-sizing: border-box;
            width: 25%;
            height: 100%;
            background: #ffffff;
            border-left: 1px solid rgb(225, 228, 230);
            box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.18);
            font-size: 18px;
            color: #666666;
            font-family: none;
            text-align: center;
            cursor: pointer;
            position: relative;
            div {
              box-sizing: border-box;
              width: 100%;
              height: 100%;
              background: #ffffff;
              line-height: 64px;
            }
            span {
              display: block;
              width: 100%;
              height: 15px;
              position: absolute;
              top: -15px;
              left: 0;
              background: #0c63bb;
            }
          }
          li:last-child {
            border-right: 1px solid rgb(225, 228, 230);
          }
        }
      }
      .p3m-major-Buttom {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div {
          box-sizing: border-box;
          width: 25%;
          height: 550px;
          position: relative;
          img {
            position: absolute;
            bottom: 0;
            left: 11.5px;
            width: 254px;
            height: 494px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一个部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.76rem;
      .part1-title {
        width: auto;
        height: auto;
        margin-left: 0.3rem;
        span {
          font-size: 0.4rem;
          font-weight: 400;
          margin-bottom: 0rem;
        }
        p,
        a {
          display: none;
        }
      }
      > img {
        width: 4.3rem;
        height: 4.16rem;
        margin-right: 0rem;
        margin-top: 0rem;
      }
    }
  }
  /* 第二个部分 */
  .part2 {
    height: 6.95rem;
    background: linear-gradient(-88deg, #ffffff 0%, #fafafa 52%, #ffffff 100%);
    .part2_main {
      width: 5.7rem;
      padding-top: 0.43rem;
      > span {
        margin-bottom: 0.25rem;
      }
      .p2m-major {
        width: 100%;
        .p2m-majorImg {
          display: none;
        }
        .p2m-major-left {
          width: 100%;
          .p2m-major-item {
            height: 1.78rem;
            padding: 0.4rem 0px;
            span {
              font-size: 0.2rem;
              font-weight: 400;
              margin-bottom: 0.15rem;
            }
            p {
              width: 4.13rem;
              font-size: 0.16rem;
              font-weight: 400;
              line-height: 0.24rem;
            }
          }
          .p2m-major-item:nth-of-type(1) {
            padding-left: 0.37rem;
            img {
              width: 0.58rem;
              height: 0.73rem;
              margin-right: 0.24rem;
            }
          }
          .p2m-major-item:nth-of-type(2) {
            padding-left: 0.3rem;
            img {
              width: 0.68rem;
              height: 0.58rem;
              margin-right: 0.2rem;
            }
          }
          .p2m-major-item:nth-of-type(3) {
            padding-left: 0.39rem;
            img {
              width: 0.53rem;
              height: 0.76rem;
              margin-right: 0.29rem;
              margin-top: 0rem;
            }
          }
        }
      }
    }
  }
  /* 第三个部分 */
  .part3 {
    height: 6.47rem;
    .part3_main {
      width: 6.3rem;
      padding-top: 0.63rem;
      span {
        margin-bottom: 0.62rem;
      }
      .p3m-major {
        width: 100%;
        .p3m-major-Top {
          margin-bottom: 0.25rem;
          .topUl {
            width: 100%;
            height: 0.53rem;
            border-top: 0.01rem solid rgb(225, 228, 230);
            .ul-item {
              box-shadow: 0px 0.3rem 0.21rem 0rem rgba(196, 197, 199, 0.18);
              font-size: 0.18rem;
              font-weight: 400;
              div {
                line-height: 0.53rem;
              }
              span {
                height: 0.08rem;
                top: -0.08rem;
              }
            }
            li:last-child {
              border-right: 1px solid rgb(225, 228, 230);
            }
          }
        }
        .p3m-major-Buttom {
          div {
            box-sizing: border-box;
            width: 25%;
            height: 3.23rem;
            position: relative;
            img {
              position: absolute;
              bottom: 0;
              left: 0.115rem;
              width: 1.51rem;
              height: 2.94rem;
              opacity: 0.27;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="internationalMarketingSms">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>国际营销短信</span>
          <p>
            国际营销短信（International  MKT  SMS）是指国内客户的出海企业与其他国家或地区运营商的用户之间发送和接收短消息的业务。不受时间、空间和地点的限制。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>

        <img
          class="reveal-right"
          src="../../assets/img/product/internationalMarketingSms/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">国际营销短信功能介绍</span>
      <div class="part2_main">
        <p class="reveal-bottom">
          百悟科技为用户提供简单、迅速、低成本的国际短信发送能力。只需接入国际短信网关就能给全球各国手机号发送信息，真正做到一点接入全球覆盖！
        </p>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/internationalMarketingSms/part2-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part3">
      <span class="part-title">国际营销短信消息架构图</span>
      <img
        class="reveal-bottom2"
        src="../../assets/img/product/internationalMarketingSms/part3-icon.png"
        alt=""
      />
    </section>
    <section class="part4">
      <span class="part-title">国际营销短信特色优势</span>
      <div class="part4_main reveal-bottom2">
        <div
          class="part4-item"
          v-for="(item, index) in part4Data"
          :key="index"
          @mousemove="part4Item(index, item.imgOver)"
          @mouseout="part4Item(index, item.img)"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span>{{ item.title }}</span>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <div class="part4_main p4-swiper">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in part4Datas"
            :key="i"
            class="swiper-item"
          >
            <div class="part4-item" v-for="(item2, i2) in item" :key="i2">
              <div>
                <img :src="item2.img" alt="" />
              </div>
              <span>{{ item2.title }}</span>
              <p>{{ item2.content }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p4m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part5">
      <span class="part-title">国际营销短信场景应用</span>
      <div class="part5_main reveal-bottom2">
        <div
          class="part5-item"
          v-for="(item, index) in part5Data"
          :key="index"
          @mousemove="part5Item(index, item.imgOver)"
          @mouseout="part5Item(index, item.img)"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span>{{ item.title }}</span>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <div class="part5_main p5-swiper">
        <el-carousel
          trigger="click"
          :interval="5000"
          arrow="always"
          ref="p5m2Carousel"
        >
          <el-carousel-item
            class="part5-item"
            v-for="(item, index) in part5Data"
            :key="index"
          >
            <div>
              <img :src="item.img" alt="" />
            </div>
            <span>{{ item.title }}</span>
            <p>{{ item.content }}</p>
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part4Data: [
        {
          img: require('../../assets/img/product/internationalMarketingSms/part4-icon1.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part4-icon1-over.png'),
          title: '全球范围覆盖',
          content: '覆盖 200+ 个国家和地区，1000+ 运营商网关，支持多国语言',
        },
        {
          img: require('../../assets/img/product/internationalMarketingSms/part4-icon2.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part4-icon2-over.png'),
          title: '实时分析报告',
          content: '实时追踪国际短信的回执状态，生成可视化图表分析报告',
        },
        {
          img: require('../../assets/img/product/internationalMarketingSms/part4-icon3.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part4-icon3-over.png'),
          title: '优质通道布局',
          content: '专用国际短信通道高效响应，实时监控通道运行，低延时高速响应',
        },
        {
          img: require('../../assets/img/product/internationalMarketingSms/part4-icon4.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part4-icon4-over.png'),
          title: '发送灵活便捷',
          content:
            'API，实现集成发送；也可随时随地登录网页，在线编辑发送。充分满足用户的不同需求，高效、稳定、灵活',
        },
      ],
      part5Data: [
        {
          img: require('../../assets/img/product/internationalMarketingSms/part5-icon1.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part5-icon1-over.png'),
          title: '客户关怀',
          content: '重大节日或者客户生日，送上节日祝福，拉近与用户的距离',
        },
        {
          img: require('../../assets/img/product/internationalMarketingSms/part5-icon2.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part5-icon2-over.png'),
          title: '活动促销',
          content: '品牌活动通过短信让用户及时知道，增加购买率',
        },
        {
          img: require('../../assets/img/product/internationalMarketingSms/part5-icon3.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part5-icon3-over.png'),
          title: '新品上线',
          content: '通过短信将新品告知用户，提升用户粘性',
        },
        {
          img: require('../../assets/img/product/internationalMarketingSms/part5-icon4.png'),
          imgOver: require('../../assets/img/product/internationalMarketingSms/part5-icon4-over.png'),
          title: '会员通知',
          content:
            '会员积分变动以及可兑换产品，通过短信直接触达用户，引导用户多次购买',
        },
      ],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.p4-swiper .p4m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      part4Datas: [],
    };
  },
  mounted() {
    // 内容初始化
    this.part4Datas = [
      JSON.parse(JSON.stringify(this.part4Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part4Data.slice(-2))),
    ];
  },
  methods: {
    part4Item(index, img) {
      $('.part4-item').eq(index).find('img').attr('src', img);
    },
    part5Item(index, img) {
      $('.part5-item').eq(index).find('img').attr('src', img);
    },
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 611px;
      height: 799px;
      z-index: -1;
      top: 9px;
      right: -31px;
    }
  }
  .part1-title {
    width: 526px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 997px;
  background: url('../../assets/img/product/internationalMarketingSms/part2-bg.png')
    no-repeat center center;
  padding-top: 79px;
  .part-title {
    margin-bottom: 45px;
  }
  .part2_main {
    width: 1111px;
    margin: 0 auto;
    text-align: center;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      text-align: start;
      line-height: 28px;
      margin-bottom: 76px;
    }
  }
}
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 719px;
  padding-top: 93px;
  text-align: center;
  .part-title {
    margin-bottom: 59px;
  }
}
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 768px;
  background: url('../../assets/img/product/internationalMarketingSms/part4-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 112px;
  .part-title {
    color: white;
    margin-bottom: 92px;
  }
  .part4_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .part4-item {
      box-sizing: border-box;
      width: 250px;
      height: 390px;
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 49px;
      div {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 14px;
      }
      span {
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      p {
        display: block;
        width: 172px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        text-align: justify;
        line-height: 25px;
      }
    }
    .part4-item:hover {
      background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      span,
      p {
        color: #ffffff;
      }
    }
  }
  .part4_main:nth-of-type(2) {
    display: none;
  }
}
.part5 {
  box-sizing: border-box;
  width: 100%;
  height: 748px;
  padding-top: 117px;
  .part-title {
    margin-bottom: 72px;
  }
  .part5_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .part5-item {
      box-sizing: border-box;
      width: 259px;
      height: 437px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 23px;
      div {
        width: 100%;
        height: 193px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 2px;
      }
      span {
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #005cb9;
        margin-bottom: 23px;
      }
      p {
        display: block;
        width: 204px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 25px;
        text-align: justify;
        width: 204px;
      }
    }
    .part5-item:hover {
      box-shadow: 1px 33px 40px 0px rgba(33, 33, 33, 0.07);
      border-radius: 18px;
      span {
        color: #005cb9;
      }
    }
  }
  .part5_main:nth-of-type(2) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 3.21rem;
        height: 4.2rem;
        top: 0.08rem;
        right: -0.04rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    height: 9.04rem;
    padding-top: 0.32rem;
    .part-title {
      margin-bottom: 0.38rem;
    }
    .part2_main {
      width: 6.2rem;
      p {
        font-size: 0.18rem;
        line-height: 0.28rem;
        margin-bottom: 0.24rem;
      }
      img {
        width: 3.23rem;
        height: 6.28rem;
      }
    }
  }
  .part3 {
    height: 4.33rem;
    padding-top: 0.36rem;
    .part-title {
      margin-bottom: 0.44rem;
    }
    img {
      height: 2.42rem;
    }
  }
  .part4 {
    height: 6.69rem;
    background: url('../../assets/img/product/whatsAppBusiness/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.57rem;
    .part-title {
      margin-bottom: 0.48rem;
    }
    .part4_main {
      width: 5.68rem;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0.2rem;
      }
      .part4-item {
        width: 2.5rem;
        height: 3.9rem;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.49rem;
        div {
          height: 0.8rem;
          margin-bottom: 0.14rem;
          img {
            max-height: 100%;
          }
        }
        span {
          font-size: 0.18rem;
          margin-bottom: 0.2rem;
        }
        p {
          width: 1.72rem;
          font-size: 0.16rem;
          line-height: 0.25rem;
        }
      }
      .part4-item:hover {
        border: 0.02rem solid #ffffff;
        box-shadow: 0rem 0.3rem 0.21rem 0rem rgba(196, 197, 199, 0.1);
      }
    }
    .part4_main:nth-of-type(1) {
      display: none;
    }
    .part4_main:nth-of-type(2) {
      display: block;
    }
  }
  .part5 {
    height: 5.74rem;
    padding-top: 0.56rem;
    .part-title {
      margin-bottom: 0.29rem;
    }
    .part5_main {
      width: 6.5rem;
      .part5-item {
        width: 3.94rem;
        height: 3.5rem;
        padding-top: 0.23rem;
        margin-left: 1.28rem;
        div {
          width: 100%;
          height: 1.84rem;
          margin-bottom: 0.12rem;
          img {
            height: 1.84rem;
          }
        }
        span {
          font-size: 0.24rem;
          margin-bottom: 0.23rem;
          color: #333333;
        }
        p {
          width: 2.04rem;
          font-size: 0.16rem;
          line-height: 0.25rem;
        }
      }
      .part5-item:hover {
        box-shadow: 0.01rem 0.33rem 0.4rem 0px rgba(33, 33, 33, 0);
        border-radius: 0.18rem;
        span {
          color: #333333;
        }
      }
    }
    .part5_main:nth-of-type(1) {
      display: none;
    }
    .part5_main:nth-of-type(2) {
      display: block;
    }
  }
}
</style>
<style lang="less">
// 轮播图
// p5
.p5-swiper {
  .el-carousel__container {
    height: 3.5rem;
    .el-carousel__arrow {
      width: 0.58rem;
      height: 0.58rem;
      background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
    }
    .el-icon-arrow-right::before,
    .el-icon-arrow-left::before {
      font-size: 0.3rem;
    }
  }
}
</style>

<template>
  <div class="callPlatform">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>呼叫中心平台</span>
          <p>
            百悟呼叫中心平台作为通过多种通信手段为客户提供交互式自助服务和人工服务的系统，通常提供业务咨询、信息查询、帐务查询、投诉与建议、业务受理、交易处理、电话回访、客户关怀、市场调查、数据整理等综合性服务。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/callPlatform/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">智能客服业务架构介绍</span>
      <img
        class="reveal-bottom2"
        src="../../assets/img/product/callPlatform/part2-icon.png"
        alt=""
      />
    </section>
    <section class="part3">
      <span class="part-title">呼叫中心平台特殊优势</span>
      <div class="part3_main reveal-bottom2">
        <div
          class="part3-item"
          v-for="(item, index) in part3Data"
          :key="index"
          @mouseover="part3ItemOver(index)"
          @mouseout="part3ItemOut(index)"
        >
          <div></div>
          <div>
            <img :src="item.img" alt="" />
          </div>
          <div>
            <p v-for="(item2, index2) in item.p" :key="index2">
              {{ item2 }}
            </p>
          </div>
        </div>
      </div>
      <div class="part3_main reveal-bottom2">
        <swiper :options="swiperOption">
          <swiper-slide class="chance-slide0">
            <div
              class="part3-item"
              v-for="(item, index) in part3Datas[0]"
              :key="index"
              @click="part3Moblie0(index)"
              :style="{
                width: '1.77rem',
                height: '4.31rem',
                background: 'url(' + item.bg + ') no-repeat',
                backgroundSize: '100% 100%',
              }"
            >
              <div></div>
              <div>
                <img :src="item.img" alt="" />
              </div>
              <div>
                <p v-for="(item2, index2) in item.p" :key="index2">
                  {{ item2 }}
                </p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="chance-slide1">
            <div
              class="part3-item"
              v-for="(item, index) in part3Datas[1]"
              :key="index"
              @click="part3Moblie0"
              :style="{
                width: '1.77rem',
                height: '4.31rem',
                background: 'url(' + item.bg + ') no-repeat 100% 100%',
                backgroundSize: '100% 100%',
              }"
            >
              <div></div>
              <div>
                <img :src="item.img" alt="" />
              </div>
              <div>
                <p v-for="(item2, index2) in item.p" :key="index2">
                  {{ item2 }}
                </p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper_bg_blue" slot="pagination"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part3Data: [
        {
          img: require('../../assets/img/product/callPlatform/part3-icon1.png'),
          bg: require('../../assets/img/product/callPlatform/part3-bg1.png'),
          p: ['三大运营商', '战略合作伙伴'],
        },
        {
          img: require('../../assets/img/product/callPlatform/part3-icon2.png'),
          bg: require('../../assets/img/product/callPlatform/part3-bg2.png'),
          p: ['线路稳定', '按需收费'],
        },
        {
          img: require('../../assets/img/product/callPlatform/part3-icon3.png'),
          bg: require('../../assets/img/product/callPlatform/part3-bg3.png'),
          p: ['一体化设计'],
        },
        {
          img: require('../../assets/img/product/callPlatform/part3-icon4.png'),
          bg: require('../../assets/img/product/callPlatform/part3-bg4.png'),
          p: ['实施快速'],
        },
        {
          img: require('../../assets/img/product/callPlatform/part3-icon5.png'),
          bg: require('../../assets/img/product/callPlatform/part3-bg5.png'),
          p: ['维护简单'],
        },
      ],
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
        // grabCursor: true, //小手掌抓取滑动
        loop: false,
      },
      part3Datas: [],
    };
  },
  mounted() {
    this.part3Datas = [
      JSON.parse(JSON.stringify(this.part3Data.slice(0, 3))),
      JSON.parse(JSON.stringify(this.part3Data.slice(-2))),
    ];
  },
  methods: {
    part3ItemOver(index) {
      $('.part3-item')
        .eq(index)
        .find('div')
        .eq(0)
        .stop()
        .animate({ opacity: '1' }, 100);
      $('.part3-item').eq(index).stop().animate({ marginTop: '-77px' }, 300);
    },
    part3ItemOut(index) {
      $('.part3-item')
        .eq(index)
        .find('div')
        .eq(0)
        .stop()
        .animate({ opacity: '0' }, 100);
      $('.part3-item').eq(index).stop().animate({ marginTop: '0px' }, 300);
    },
    part3Moblie0() {
      $('.part3-item').hover(
        function () {
          $(this).stop().animate({ marginTop: '-0.48rem' }, 300);
          $(this).find('div').eq(0).stop().animate({ opacity: '1' }, 100);
        },
        function () {
          $(this).stop().animate({ marginTop: '0rem' }, 300);
          $(this).find('div').eq(0).stop().animate({ opacity: '0' }, 100);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  > img {
    width: 607px;
    height: 585px;
    margin-right: -99px;
    margin-top: 1px;
    z-index: -1;
  }
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 1064px;
  background: linear-gradient(-88deg, #ffffff 0%, #f2f4fa 52%, #ffffff 100%);
  padding-top: 76px;
  .part-title {
    margin-bottom: 80px;
  }
  img {
    display: block;
    width: 1084px;
    margin: 0 auto;
  }
}
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 1001px;
  background: url('../../assets/img/product/callPlatform/part3-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 104px;
  .part-title {
    color: #ffffff;
    margin-bottom: 182px;
  }
  .part3_main {
    width: 1090px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .part3-item {
      box-sizing: border-box;
      width: 207px;
      height: 502px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 33px;
      div:nth-of-type(1) {
        width: 68px;
        height: 10px;
        background: linear-gradient(-58deg, #6c97ce 0%, #005cb9 100%);
        border-radius: 5px;
        margin-bottom: 118px;
        opacity: 0;
      }
      div:nth-of-type(2) {
        height: 86px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 52px;
      }
      div:nth-of-type(3) {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
        }
      }
    }
    .part3-item:nth-of-type(1) {
      background: url('../../assets/img/product/callPlatform/part3-bg1.png')
        no-repeat center center;
    }
    .part3-item:nth-of-type(2) {
      background: url('../../assets/img/product/callPlatform/part3-bg2.png')
        no-repeat center center;
    }
    .part3-item:nth-of-type(3) {
      background: url('../../assets/img/product/callPlatform/part3-bg3.png')
        no-repeat center center;
    }
    .part3-item:nth-of-type(4) {
      background: url('../../assets/img/product/callPlatform/part3-bg4.png')
        no-repeat center center;
    }
    .part3-item:nth-of-type(5) {
      background: url('../../assets/img/product/callPlatform/part3-bg5.png')
        no-repeat center center;
    }
  }
  .part3_main:nth-of-type(2) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 3.72rem;
        height: 3.59rem;
        margin-right: -0.29rem;
        margin-top: 0px;
        z-index: -1;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    height: 6.29rem;
    padding-top: 0.32rem;
    .part-title {
      margin-bottom: 0.46rem;
    }
    img {
      width: 6.68rem;
    }
  }
  .part3 {
    height: 7.23rem;
    padding-top: 0.52rem;
    background: url('../../assets/img/product/callPlatform/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.61rem;
    }
    .part3_main:nth-of-type(1) {
      display: none;
    }
    .part3_main:nth-of-type(2) {
      display: block;
      width: 6.4rem;
      position: relative;
      .swiper-container {
        height: 100%;
        padding-top: 0.48rem;
      }
      .swiper-pagination {
        left: 0;
        width: 100%;
        bottom: -0.63rem;
      }
      .swiper-slide {
        width: 6rem !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0rem 0.2rem;
        .part3-item {
          padding-top: 0.28rem;
          div:nth-of-type(1) {
            width: 0.59rem;
            height: 0.08rem;
            background: linear-gradient(-58deg, #6c97ce 0%, #005cb9 100%);
            border-radius: 0.04rem;
            margin-bottom: 1rem;
          }
          div:nth-of-type(2) {
            height: 0.86rem;
            margin-bottom: 0.4rem;
            img {
              height: 0.86rem;
            }
          }
          div:nth-of-type(3) {
            height: 0.45rem;
            p {
              font-size: 0.16rem;
              line-height: 0.24rem;
            }
          }
        }
      }
      .swiper-slide:nth-of-type(2) {
        justify-content: flex-start;
        .part3-item {
          margin-right: 0.32rem;
        }
      }
    }
  }
}
</style>
<style>
.callPlatform .swiper_bg_blue {
  width: 6.4rem;
}
</style>
